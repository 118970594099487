import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import logo from "./../img/LOGO_pozitiv_transparentback.png"

export default function Sidebar() {
  const [state, setState] = React.useState({
    left: false,
  });


  const divStyle = {
      cursor: 'pointer', // Change cursor to pointer on hover
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >

          <div style={{padding:"20px 0px"}}>
              <img src={logo} alt="" width={"220"}/>
          </div>
       <div style={{padding:"10px"}}>
            <p>Klenovićeva 31, Mičevec, Velika Gorica</p>
        <p>Trgovačko društvo upisano je u Sudski registar Trgovačkog suda u Zagrebu pod matičnim brojem subjekta 080722235</p>
        <p>Temeljni kapital 100.000,00 kuna - uplaćen u cjelosti</p>
        <p>Hrvatska poštanska banka d.d.(OIB: 87939104217), Jurišićeva 4, 10 000 Zagreb</p>
        <p>IBAN: HR6223900011101320944</p>
        <p>Članovi društva: Hrvoje Sremić i Petar Rebernišak</p>
       </div>
    </Box>
  );

  return (
    <li className={"nav-item"}>
      {['Tvrtka'].map((anchor) => (
        <React.Fragment key={anchor}>
          <a className={"nav-link active"} onClick={toggleDrawer(anchor, true)} style={divStyle}>{anchor}</a>
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </li>
  );
}
