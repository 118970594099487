import './App.css';
import Index from "./Components/Index";

import '../src/scss/styles.scss';

function App() {
  return (
    <div className="App">
     <Index/>
    </div>
  );
}

export default App;

