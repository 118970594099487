import {TextField} from "@mui/material";
import React from "react";

const TextFieldInput = ({formParams, nameInput, labelInput, handleUserInput, value}) => {
    return (
        <TextField
            value={value}
            error={formParams === null}
            helperText={formParams === null ? 'Ovo polje je obavezno' : ''}
            name={nameInput}
            id="outlined-basicValid"
            label={labelInput}
            variant="outlined"
            sx={{m: 1}} onChange={handleUserInput}/>)
}
export default TextFieldInput;
