export const getAllManufacturers = async () => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_BASE_URL}/drone/manufacturers/`,
            {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                },
            }
        );
        return response.json();
    } catch (error) {
        console.error(error);
    }
};


export const getAllModels = async (model) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_BASE_URL}/drone/models/${model}`,
            {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                },
            }
        );
        return response.json();
    } catch (error) {
        console.error(error);
    }
};


export const getCalculation = async (model, location) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_BASE_URL}/drone/calculation/${model}/${location}`,
            {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                },
            }
        );
        return response.json();
    } catch (error) {
        console.error(error);
    }
}


export const getDronInfo = async (info) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_BASE_URL}/drone/form-info/${info}`,
            {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                },
            }
        );
        return response.json();
    } catch (error) {
        console.error(error);
    }
};


export const getAllDroneLocation = async () => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_BASE_URL}/drone/locations/`,
            {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                },
            }
        );
        return response.json();
    } catch (error) {
        console.error(error);
    }
};


export const sendMail = async (formData) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_BASE_URL}/drone/mail/`,
            {
                method: 'POST',
                body: formData,
                headers: {
                    Accept: 'application/json',
                },
            }
        );
        return await response.json();
    } catch (error) {
        console.error(error);
        return ([{response: error, status: 'Bad'}]);
    }
};
