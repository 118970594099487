export const initialFormState = ({
    name: '',
    oib: '',
    address: '',
    email: '',
    city: '',
    zip_code: '',
    serial_number: '',
    year: '',
    manufacturer: '',
    weight: '',
    flying_class: '',
    drone_class: '',
    purpose: '',
    location: '',
    kasko_type: false,
    insurance: '',
    value: false,
    rest_address: false,
    damages: false,
    note: false,
    model: '',
    additionalManufactured: false,
})


export const check = ({
    checkbox1: false,
    checkbox2: false
})
