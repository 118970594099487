import React, {useState} from "react";
import logo from "../img/LOGO_pozitiv_transparentback.png"
import kasko from "../img/kasko.jpg"
import odgovornost from "../img/odgovornost.jpg"
import insurance from "../img/insurance01.jpg"
import health from "../img/health.png"
import liability from "../img/liability.jpg"
import pokretnine from "../img/pokretnine.jpg"
import life from "../img/life.jpg"
import olderCouple from "../img/OlderCouple1-1.jpg"
import Modal from "./Modal";
import {Alert} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Collapse from "@mui/material/Collapse";
import Sidebar from "./Sidebar";
import upitnik from "../document/Upitnik za osiguranje bespilotnog zrakoplova.docx"
import podatci from "./../document/Informacije o obradi osobnih podataka.pdf"
import posrednik from "./../document/Informacije o posredniku u osiguranju.pdf"

export default function Index() {
    const [errorMsg, setErrorMsg] = useState(false)
    const [openAlert, setOpenAlert] = React.useState(true);

    return (
        <>
            {errorMsg &&
                <Collapse in={openAlert}>
                    <Alert
                        style={{position: 'fixed', zIndex: '1', left: '0px', bottom: '0'}}
                        severity={errorMsg === 'Bad' ? "error" : "success"}
                        action={
                            <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                    setOpenAlert(false);
                                    setErrorMsg(false);
                                }}
                            >
                                <CloseIcon fontSize="inherit"/>
                            </IconButton>
                        }
                        sx={{mb: 2}}
                    >
                        {errorMsg === 'Bad' ? "Došlo je do greške" : "Uspješno ste poslali upit"}
                    </Alert>
                </Collapse>}
            <nav className="navbar navbar-expand-lg navbar-light" style={{maxWidth: "1900px", margin: "auto"}}>
                <a className="navbar-brand" href="#"><img src={logo} alt=""/></a>
                <button className="navbar-toggler" type="button" data-toggle="collapse"
                        data-target="#navbarSupportedContent"
                        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>

                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav ml-auto">
                        <li className="nav-item active">
                            <a className="nav-link" href="#osiguranje">Osiguranje <span
                                className="sr-only">(current)</span></a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link active" href="#registration">Registracija</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link active" href="#oNama">O nama</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link active" href="#ostalo">Ostalo</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link active" href="#kontakt">Kontakt</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link active" href={upitnik}
                               target="_blank">Upitnik</a>
                        </li>

                            <Sidebar/>

                    </ul>
                </div>
            </nav>


            <div className="jumbotron">
                <h1>Osiguranje drona</h1>
                <h6 className={"subtitle"}>Ugovorite online policu obveznog osiguranja za dron</h6>
                <Modal className={"form"} setErrorMsg={setErrorMsg} setOpenAlert={setOpenAlert}/>
            </div>

            <div className="osiguranje mt-5" id="osiguranja">
                <div className="title">
                    <h1>Dron osiguranje</h1>
                </div>
                <h5 style={{textAlign: "center", marginBottom: "50px"}}>Premija osiguranja već od 59,73€</h5>
                <div className="mobile">
                    <div className="mobile01">
                        <img src={odgovornost} className="card-img-top" alt="..."/>
                        <div className="card-body">
                            <h5 className="card-title">Osiguranje od odgovornosti operatora bespilotnog
                                zrakoplova</h5>
                            <p className="card-text">
                                Osiguranje drona, registracija operatora, osposobljavanje udaljenog pilota i prijava
                                leta je procedura koju je potrebno odraditi za legalno letenje.
                                Višegodišnje iskustvo i mnoštvo zadovoljnih operatora i pilota su najveća referenca
                                našega rada. Za sve upite i nejasnoće oko osiguranja drona, registracije operatora i
                                osposobljavanja udaljenih pilota slobodno nam se javite.

                                Ugovaranje police osiguranja od odgovornosti operatora bespilotnog zrakoplova obvezno je za sve modele čiji je MTOM veći od 20 kg i sve bespilotne zrakoplove koji izvode letačke operacije, kao na primjer DJI Mini 4 Pro, DJI Air 3, DJI Mavic 3. Obveza
                                osiguranja
                                proizlazi iz Uredbe (EZ) 785/2004 o zahtjevima za zračne
                                prijevoznike i operatore zrakoplova u vezi s osiguranjem. Minimalna svota osiguranja
                                iznosi
                                750.000 SDR-a. Ugovorom o osiguranju obuhvaćena je odgovornost operatora za štete
                                trećim
                                osobama zbog smrti, tjelesne ozljede, narušavanja zdravlja, uništenja ili oštećenja
                                stvari.
                                Teritorijalno pokriće Republike Hrvatske, Europe ili svijeta. Moguć je upis dodatnih
                                osiguranika
                                na policu kao i izdavanje certifikata na engleskom jeziku.</p>
                        </div>
                    </div>
                    <div className="mobile02">
                        <img src={kasko} className="card-img-top" alt="..."/>
                        <div className="card-body">
                            <h5 className="card-title">Kasko osiguranje bespilotnog zrakoplova</h5>
                            <p className="card-text">Kasko osiguranjem pokrivene su štete nastale oštećenjem,
                                uništenjem ili
                                nestankom bespilotnog zrakoplova od sljedećih rizika: zrakoplovne nesreće (sudar,
                                pad), pad ili
                                udar nekog predmeta; požara; šteta na električnoj instalaciji; izravnog udara groma;
                                eksplozije;
                                oluje; tuče ili grada; krađe; zlonamjernih postupaka trećih osoba... Moguće je
                                osigurati cijeli
                                sustav ili samo bespilotni zrakoplov.</p>
                        </div>
                    </div>
                </div>

                <div className="desktop" id="osiguranje">
                    <div className="container">
                        <div className="row">
                            <div className="col-6">
                                <div className="desktop01">
                                    <img src={odgovornost} className="card-img-top" alt="..."/>
                                    <div className="card-body">
                                        <h5 className="card-title">Osiguranje od odgovornosti operatora bespilotnog
                                            zrakoplova</h5>
                                        <p className="card-text">
                                            Osiguranje drona, registracija operatora, osposobljavanje udaljenog pilota i
                                            prijava leta je procedura koju je potrebno odraditi za legalno letenje.
                                            Višegodišnje iskustvo i mnoštvo zadovoljnih operatora i pilota su najveća
                                            referenca našega rada. Za sve upite i nejasnoće oko osiguranja drona,
                                            registracije operatora i osposobljavanja udaljenih pilota slobodno nam se
                                            javite.

                                            Ugovaranje police osiguranja od odgovornosti
                                            operatora bespilotnog
                                            zrakoplova obvezno je za sve modele čiji je MTOM veći od 20 kg i sve
                                            bespilotne
                                            zrakoplove koji izvode letačke operacije, kao na primjer DJI Mini 4 Pro, DJI Air 3, DJI Mavic 3. Obveza osiguranja proizlazi iz Uredbe (EZ)
                                            785/2004 o zahtjevima za zračne prijevoznike i operatore zrakoplova u
                                            vezi s
                                            osiguranjem. Minimalna svota osiguranja iznosi 750.000 SDR-a. Ugovorom o
                                            osiguranju obuhvaćena je odgovornost operatora za štete trećim osobama
                                            zbog smrti,
                                            tjelesne ozljede, narušavanja zdravlja, uništenja ili oštećenja stvari.
                                            Teritorijalno pokriće Republike Hrvatske, Europe ili svijeta. Moguć je
                                            upis dodatnih
                                            osiguranika na policu kao i izdavanje certifikata na engleskom
                                            jeziku.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="desktop02">
                                    <img src={kasko} className="card-img-top" alt="..."/>
                                    <div className="card-body">
                                        <h5 className="card-title">Kasko osiguranje bespilotnog zrakoplova</h5>
                                        <p className="card-text"> Kasko osiguranjem pokrivene su štete nastale
                                            oštećenjem,
                                            uništenjem ili nestankom bespilotnog zrakoplova od sljedećih rizika:
                                            zrakoplovne
                                            nesreće (sudar, pad), pad ili udar nekog predmeta; požara; šteta na
                                            električnoj
                                            instalaciji; izravnog udara groma; eksplozije; oluje; tuče ili grada;
                                            krađe;
                                            zlonamjernih postupaka trećih osoba... Moguće je osigurati cijeli sustav
                                            ili samo
                                            bespilotni zrakoplov.</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>


            <div className="registration" id="registration">
                <div className="mobile-registracija mt-5">
                    <div className="title" style={{marginTop: "-2%"}}>
                        <h1>Koraci u registraciji:</h1>
                    </div>
                    <div className="card">
                        <div className="card-body">
                            <h3>1.</h3>
                            <br/>
                            Ugovaranje police osiguranja od odgovornosti operatora bespilotnog zrakoplova.
                            <br/>
                            <a href={upitnik} target="_blank">Upitnik</a>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-body">
                            <h3>2.</h3>
                            <br/>
                            Registracija operatora na stranicama CCAA. <a
                            href="https://www.ccaa.hr/letenje-dronom-98073">Registracija operatora</a>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-body">
                            <h3> 3.</h3>
                            <br/>
                            Polaganje ispita za udaljene pilote provodi se neovisno o registraciji operatora (pilot
                            ne
                            mora posjedovati dron).
                            <br/>
                            <a href="https://www.ccaa.hr/osposobljavanje-za-udaljene-pilote-28241">Polaganje ispita</a>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-body">
                            <h3> 4.</h3>
                            <br/>
                            Registracija pilota i prijava leta na AMC portalu. <a
                            href="https://amc.crocontrol.hr/">Prijava leta</a>
                        </div>
                    </div>
                </div>


                <div className="desktop-registracija">
                    <div className="mazi-container mt-5" id="registracija">


                        <div className="title" style={{marginTop: "-2%", marginBottom: "-40px"}}>
                            <h1>Koraci u registraciji:</h1>
                        </div>
                        <div className="mazi-wrapper">

                            <div className="sec-md-35">
                                <div className="cards">
                                    <div className="card left-t">

                                        <h2>1.</h2>

                                        <p>Ugovaranje police osiguranja od odgovornosti operatora bespilotnog
                                            zrakoplova.
                                            <br/>
                                            <a href={upitnik} target="_blank">Upitnik</a>
                                        </p>


                                    </div>
                                    <div className="card left-b">

                                        <h2>3.</h2>
                                        <p>Polaganje ispita za udaljene pilote provodi se neovisno o registraciji
                                            operatora
                                            (pilot ne
                                            mora posjedovati dron). <br/>
                                            <a href="https://www.ccaa.hr/osposobljavanje-za-udaljene-pilote-28241" target={"_blank"}>Polaganje
                                                ispita</a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="sec-md-30">
                                <svg xmlns="http://www.w3.org/2000/svg"
                                     width="408.598"
                                     height="513.23" viewBox="0 0 408.598 513.23">
                                    <defs>
                                        <clipPath id="clip-path">
                                            <rect id="Rectangle_30" data-name="Rectangle 30" width="108" height="108"
                                                  transform="translate(73 523)" fill="#8247e5"/>
                                        </clipPath>
                                    </defs>
                                    <g id="how-it-works-bg" transform="translate(-750 -2540)">
                                        <g id="logo-icon" transform="translate(833 2017)" clip-path="url(#clip-path)">
                                            <g id="Group_36" data-name="Group 36" transform="translate(83.336 523)">
                                                <path id="Path_106" data-name="Path 106"
                                                      d="M1079.107,592.009V649.6l20.242,20.242V611.585Z"
                                                      transform="translate(-1079.107 -592.009)" fill="#8247e5"/>
                                                <path id="Path_107" data-name="Path 107"
                                                      d="M1099.349,592.009V649.6l-20.242,20.242V611.585Z"
                                                      transform="translate(-1012.072 -592.009)" fill="#8247e5"/>
                                                <path id="Path_108" data-name="Path 108"
                                                      d="M1079.107,592.009v87.758l20.242,20.242V611.585Z"
                                                      transform="translate(-1045.59 -592.009)" fill="#8247e5"/>
                                            </g>
                                        </g>
                                        <g className="all-path">
                                            <g className="no1">
                                                <path id="Path_109" data-name="Path 109"
                                                      d="M925.135,2566.549v209.384H754.115"
                                                      transform="translate(0 35)" fill="none" stroke="#8247e5"
                                                      stroke-width="2"/>
                                                <circle id="Ellipse_12" data-name="Ellipse 12" cx="4.5" cy="4.5" r="4.5"
                                                        transform="translate(750 2806)" fill="#8247e5"/>
                                            </g>
                                            <g className="no2">
                                                <path id="Path_309" data-name="Path 309"
                                                      d="M766.98,2566.549v209.384H926.6"
                                                      transform="translate(227.885 32.966)" fill="none" stroke="#8247e5"
                                                      stroke-width="2"/>
                                                <circle id="Ellipse_18" data-name="Ellipse 18" cx="4.5" cy="4.5" r="4.5"
                                                        transform="translate(1149.598 2803.966)" fill="#8247e5"/>
                                            </g>
                                            <g className="no3">
                                                <path id="Path_110" data-name="Path 110"
                                                      d="M963.781,2600.978v394.874H924.015"
                                                      transform="translate(-14 52.878)" fill="none" stroke="#8247e5"
                                                      stroke-width="2"/>
                                                <circle id="Ellipse_13" data-name="Ellipse 13" cx="4.5" cy="4.5" r="4.5"
                                                        transform="translate(905.515 3044.23)" fill="#8247e5"/>
                                            </g>
                                            <g className="no4">
                                                <path id="Path_308" data-name="Path 308"
                                                      d="M924.015,2600.978v394.874h38.771"
                                                      transform="translate(48.61 52.878)" fill="none" stroke="#8247e5"
                                                      stroke-width="2"/>
                                                <circle id="Ellipse_17" data-name="Ellipse 17" cx="4.5" cy="4.5" r="4.5"
                                                        transform="translate(1006.895 3044.23)" fill="#8247e5"/>
                                            </g>
                                        </g>
                                    </g>
                                </svg>
                            </div>
                            <div className="sec-md-35">
                                <div className="cards">
                                    <div className="card right-t">

                                        <h2>2.</h2>

                                        <p> Registracija operatora na stranicama CCAA. <br/> <a
                                            href="https://www.ccaa.hr/letenje-dronom-98073" target={"_blank"}>Registracija operatora</a>
                                        </p>

                                    </div>
                                    <div className="card right-b">
                                        <h2>4.</h2>
                                        <p>Registracija pilota i prijava leta na AMC portalu. <br/> <a
                                            href="https://amc.crocontrol.hr/" target={"_blank"}>Prijava leta</a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="about" id="oNama">
                <div className="title">
                    <h1>O nama</h1>
                </div>
                <p>Prvu policu osiguranja od odgovornosti operatora bespilotnog zrakoplova izradili smo 2014. godine i
                    od tada
                    aktivno surađujemo s CCAA i osiguravajućim društvima na razvoju proizvoda za bespilotne zrakoplove.
                    S
                    vremenom smo proširili teritorijalna pokrića za osiguranja od odgovornosti, izradili prve police
                    kasko
                    osiguranja, a zadnja aktivnost je bila na izradi cjenika po kategorijama u skladu s uredbama
                    Europske
                    komisije (EU) 2019/945 i 2019/947. Agencija za zastupanje u osiguranju Semel pro semper d.o.o.
                    osnovana je u
                    2. mjesecu 2010. godine. Ideja je na jednome mjestu klijentima pružiti ispravne i potpune
                    informacija iz
                    svijeta osiguranja, a osiguranje bespilotnih zrakoplova najbolji je primjer za to. </p>
            </div>

            <div className="button">
                <a href={posrednik} target="_blank">
                    <button type="button" className="btn btn-lg btn-block">Informacije o posredniku u osiguranju
                    </button>
                </a>

                <a href={podatci} target="_blank">
                    <button type="button" className="btn btn-lg btn-block">Informacije o obradi osobnih podataka
                    </button>
                </a>
            </div>

            <div style={{backgroundColor: "white", padding: "2%"}}>
                <div className="title" id="ostalo">
                    <h1>Ostale vrste osiguranja</h1>
                </div>
                <div className="other">
                    <div className="card-deck">
                        <div className="card">
                            <img src={insurance} className="card-img-top" alt="..."/>
                            <div className="card-body">
                                <h5 className="card-title">Osiguranje imovine</h5>
                                <p className="card-text">
                                    <ul>
                                        <li>Osiguranje građevinskog dijela</li>
                                        <li>Osiguranje stvari kućanstva</li>
                                        <li>Osiguranje opreme</li>
                                        <li>Osiguranje zaliha</li>
                                    </ul>
                                </p>
                            </div>
                        </div>
                        <div className="card">
                            <img src={health} className="card-img-top" alt="..."/>
                            <div className="card-body">
                                <h5 className="card-title">Zdravstveno osiguranje</h5>
                                <p className="card-text">
                                    <ul>
                                        <li>Dodatno zdravstveno</li>
                                        <li>Sistematski pregledi</li>
                                        <li>Dopunsko zdravstveno</li>
                                        <li>Dopunsko s pokrićem B liste lijekova</li>
                                        <li>Putno zdravstveno</li>
                                    </ul>
                                </p>
                            </div>
                        </div>
                        <div className="card">
                            <img src={liability} className="card-img-top" alt="..."/>
                            <div className="card-body">
                                <h5 className="card-title">Osiguranje od odgovornosti</h5>
                                <p className="card-text">
                                    <ul>
                                        <li>Opća odgovornost</li>
                                        <li>Odgovornost prema djelatnicima</li>
                                        <li>Profesionalna odgovornost</li>
                                        <li>Osiguranje prijevoznikove odgovornosti</li>
                                    </ul>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="card-deck">
                        <div className="card">
                            <img src={pokretnine} className="card-img-top" alt="..."/>
                            <div className="card-body">
                                <h5 className="card-title">Osiguranje pokretnina</h5>
                                <p className="card-text">
                                    <ul>
                                        <li>Autoodgovornost</li>
                                        <li>Autokasko</li>
                                        <li>Osiguranje od odgovornosti brodica i jahti</li>
                                        <li>Kasko osiguranje brodica i jahti</li>
                                        <li>Osiguranje od odgovornosti operatora zrakoplova</li>
                                        <li>Kasko osiguranje zrakoplova</li>
                                    </ul>
                                </p>
                            </div>
                        </div>
                        <div className="card">
                            <img src={life} className="card-img-top" alt="..."/>
                            <div className="card-body">
                                <h5 className="card-title">Životno osiguranje</h5>
                                <p className="card-text">
                                    <ul>
                                        <li>Mješovito osiguranje života</li>
                                        <li>Osiguranje od teških bolesti</li>
                                        <li>Osiguranje od nezgode</li>
                                        <li>Osiguranje doživljenja</li>
                                        <li>Investicijsko osiguranje</li>
                                        <li>Certifikati</li>
                                    </ul>
                                </p>
                            </div>
                        </div>
                        <div className="card">
                            <img src={olderCouple} className="card-img-top" alt="..."/>
                            <div className="card-body">
                                <h5 className="card-title">Dobrovoljni mirovinski fond</h5>
                                <p className="card-text">
                                    <ul>
                                        <li>Porezno priznati izdatak</li>
                                        <li>Državna poticajna sredstva</li>
                                        <li>Isplata nakon 55. godine života</li>
                                    </ul>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <footer>
                <div className="contact" id="kontakt">
                    <div className="text" style={{textAlign: "center"}}>
                        <h3><img src={logo} className={"bottomLogo"} alt=""/></h3>
                        <div className="email">
                            <p>Semel pro semper d.o.o.</p>
                            <p>hrvoje.sremic@sps.hr </p>
                            <p>091/5698050</p>
                        </div>
                    </div>
                </div>
            </footer>
            <div className={"copyright"}>
                <p className={"copyright-p"}>Copyright © Hrvoje Sremić</p>
            </div>


        </>
    )
}
